<template>
        <div>
            <v-layout row justify-center>
                <v-flex xs12>
                    <div class="custom-section">
                        <div class="card-section">
                            <v-card color="rgba(255, 255, 255, 0.98)" >
                                <v-card-text>
                                    <img src="/static/img/impa-logo.png" class="img-responsive mb-4" >
                                    <h2 class="mb-4">iQuote</h2>
                                    <p class="fs-14">Enter username and password to access.</p>
                                    <v-layout row wrap no-gutters>
                                        <v-flex xs12>
                                            <v-text-field label="Username" v-model="email" :rules="emailRules" required class="ma-2">
                                            </v-text-field>
                                            <v-text-field 
                                                class="ma-2"
                                                label="Password" 
                                                v-model="password" 
                                                type="password" 
                                                :rules="passwordRules" 
                                                required></v-text-field>
                                        </v-flex>
                                        <v-flex xs12 sm12 md12>
                                            <v-checkbox 
                                                color="primary" 
                                                label="Remember me" 
                                                v-model="checkbox"
                                            ></v-checkbox>
                                        </v-flex>
                                        <v-flex xs6 sm12 md12>
                                            <v-btn rounded @click="submit" block color="primary" class="mb-2">{{$t('message.loginNow')}}</v-btn>
                                        </v-flex>
                                        <v-flex xs12 sm12 md12 class="text-center">
                                            <router-link class="mb-2" to="/session/forgot-password">{{$t('message.forgotPassword')}}?</router-link>
                                        </v-flex>
                                    </v-layout>
                                </v-card-text>
                            </v-card>
                        </div>

                    </div> 
                </v-flex>
            </v-layout>

        </div>
</template>

<script>
import firebase from "firebase/app";
import { mapGetters } from "vuex";
import SessionSliderWidget from "Components/Widgets/SessionSlider";
import AppConfig from "Constants/AppConfig";

import AuthService from "../../auth/AuthService";

const auth = new AuthService();
const { login, logout, authenticated, authNotifier } = auth;

export default {
  components: {
    SessionSliderWidget
  },
  data() {
    return {
      checkbox: false,
      valid: false,
      username: "",
      password: "",
      passwordRules: [v => !!v || "Password is required"],
      appLogo: AppConfig.appLogo2,
      brand: AppConfig.brand
    };
  },
  methods: {
    submit() {
      const user = {
        username: this.username,
        password: this.password
      };
      this.$store.dispatch("login", {
        user
      });
    },
    onCreateAccount() {
      this.$router.push("/session/sign-up");
    }
  }
};
</script>
<style>
 .custom-section {
    background-image:url('/static/img/shipping.jpg');
    background-size: auto auto;
    background-size: cover;
    text-align: center;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 600px;
    height: 100%;
    overflow: hidden;
    background-repeat: no-repeat;
    top: 0;
    bottom: 0;
    width:100%;
  }
  .card-section{
      align:'center';
      padding-left:20px;
      padding-right: 10px;
  }
  .centered-content{
    /* width:50%; */
    position: absolute;
    top: 50%;
    left: 50%;
    color:white;
    transform: translate(-50%, -50%);
  }
  .bottom-right-txt{
    position: absolute;
    color:white;
    bottom: 8px;
    right: 16px;
  }
  .box{
  width: 50%;
  padding: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%); 
  background-color:rgba(10,10,10,0.9);
  border-radius: 60px;
  box-shadow: 10px 12px 10px 6px rgba(10,10,50,0.9) ;
  text-align: center; 
}
</style>
